import {Warranty} from "../helpers/warranty/Warranty";
import {PoolsContainer} from "./poolscontainer/PoolsContainer";
import {useParams} from "react-router-dom";
import {FiberDetail} from "./detail/FiberDetail";
import {Carousel} from "react-responsive-3d-carousel";
import {useMemo} from "react";

function Title(title, id) {
    if (!id) {
        return (
            <div>
                <h3>{title}</h3>
            </div>
        )
    }
}

export const Fiber = () => {
    let params = useParams()
    const MEDIA_ARRAY = [
        <img src="/assets/portfolio/0001.jpg" alt="slide_01"/>,
        <img src="/assets/portfolio/0002.jpg" alt="slide_02"/>,
        <img src="/assets/portfolio/0003.jpg" alt="slide_03"/>,
        <img src="/assets/portfolio/0004.jpg" alt="slide_04"/>,
        <img src="/assets/portfolio/0005.jpg" alt="slide_05"/>,
    ]

    const mediaArray = useMemo(() => {
        return MEDIA_ARRAY.slice(0, 5)
    }, MEDIA_ARRAY)

    return (
        <main className="fiber__container">
            <Warranty duration={10} />
                {
                    Title('Piscinas de fibra', params.id)
                }
            <section>
                {
                    params.id ? <FiberDetail id={params.id} />
                        : <PoolsContainer />
                }
            </section>
            <section>
                {
                    Title('Nuestros trabajos', params.id)
                }
                <Carousel spread={"narrow"} showStatus={false} interval={4000} depth={6} showIndicators={false}>
                    { mediaArray }
                </Carousel>
            </section>
        </main>
    )
}
